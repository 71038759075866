<template>
    <div class="container-fluid auth-layout bg-white">
      <b-row class="auth-inner m-0 p-32">
        <!-- Register-->
            <b-col
                lg="6" 
                >
                <slot></slot>    
            </b-col>
        <!-- /Register-->
        <!-- Left Text-->
            <b-col
            lg="6"
            class="d-none d-lg-block bg-white "
            >
            <div class="w-100">
                <b-img class="auth-image"
                src="@/assets/images/banner/auth-banner.png"
                />
            </div>
            </b-col>
        <!-- /Left Text-->
  

      </b-row>
    </div>
  </template>
  
  <script>
  /* eslint-disable global-require */
  import {
    BRow, BCol, BImg,
  } from 'bootstrap-vue'
  
  export default {
    components: {
      BRow,
      BImg,
      BCol,
    },
  }
  /* eslint-disable global-require */
  </script>
  
  <style lang="scss" >
  @import '@core/scss/vue/pages/page-auth.scss';
  @media (max-width:1025px){
    .auth-layout{
      height: 100vh !important;
      background-color: #ffffff !important;
      overflow-y: auto;
   
    }

  }
  </style>
  